import React from "react";
import Button from "@mui/material/Button";
import { Link, useNavigate } from 'react-router-dom';

import "./BannerInicio.css";
import { useTheme } from "@mui/material/styles";

const BannerInicio = ({ height, backgroundImg }) => {
  const theme = useTheme();

  const handleContactClick = () => {
    const contactSection = document.getElementById("contacto");
    if (contactSection) {
      contactSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <section
      className="hero"
      style={{
        height: height ? `${height}px` : "100vh", // Usamos la prop "height" para establecer la altura del componente
        backgroundColor: theme.palette.primary.main,
      }}
    >
      <div className="hero-overlay">
        <div className="banner-container">
          <img src={backgroundImg} alt="banner" className="banner-img" />
        </div>
        <div className="banner-content">
          <h1
            style={{
              color: theme.palette.secondary.main,
              fontFamily: theme.typography.h1.fontFamily,
            }}
          >
            MesVida Fitness
          </h1>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>

          <span
            style={{
              color: theme.palette.tertiary.main,
              fontFamily: theme.typography.h1.fontFamily,
              fontSize: "28px",
              fontStyle: "italic",
            }}
          >
            LA REVOLUCIÓN DEL FITNESS LLEGA A
          </span>
          <span
            style={{
              color: theme.palette.secondary.main,
              fontFamily: theme.typography.h1.fontFamily,
              fontSize: "40px",
              fontWeight: "bolder",
              marginLeft: "10px",
            }}
          >
            ABRERA
          </span>
          <br></br>
          <br></br>
          <span
            style={{
              color: theme.palette.secondary.main,
              fontFamily: theme.typography.h1.fontFamily,
              fontSize: "40px",
              fontWeight: "bolder",
              marginRight: "10px",
            }}
          >
            ÚNETE A NOSOTROS
          </span>
          <span
            style={{
              color: theme.palette.tertiary.main,
              fontFamily: theme.typography.h1.fontFamily,
              fontSize: "28px",
              fontStyle: "italic",
              marginTop: "40px",
            }}
          >
            Y VIVE LA DIFERENCIA
          </span>
          <br></br>
          <br></br>
          <span
            style={{
              color: theme.palette.tertiary.main,
              fontFamily: theme.typography.h1.fontFamily,
              fontSize: "28px",
            }}
          >
            PARA
          </span>
          <span
            style={{
              color: theme.palette.secondary.main,
              fontFamily: theme.typography.h1.fontFamily,
              fontSize: "40px",
              fontWeight: "bolder",
              marginRight: "10px",
              marginLeft: "10px",
            }}
          >
            MESVIDA
          </span>
          <span
            style={{
              color: theme.palette.tertiary.main,
              fontFamily: theme.typography.h1.fontFamily,
              fontSize: "28px",
            }}
          >
            NO ERES UNO MÁS ERES TÚ
          </span>
          <div className="banner-button-container">
            <Button
              variant="contained"
              component={Link}
              to="/NuevoCliente"
              sx={{
                backgroundColor: theme.palette.secondary.main,
                color: theme.palette.tertiary.main,
                "&:hover": {
                  backgroundColor: theme.palette.tertiary.main,
                  color: theme.palette.primary.main,
                },
                fontFamily: theme.typography.button.fontFamily,
              }}
            >
              ¡Únete ya!
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BannerInicio;

// src/pages/EntrenamientoPersonal/EntrenamientoPersonal.js

import React, { useEffect }from "react";
import BannerH1 from "../../Components/BannerH1/BannerH1";
import CenteredText from "../../Components/CenteredText/CenteredText";
import WaveSeparator from "../../Components/WaveSeparator/WaveSeparator";

import imgBackground from "../../assets/FotosGimnasio/DSC01488-3-min.jpg";
import imgPromocion1 from "../../assets/Promociones/PromocionInauguracion.png";


import theme from "../../theme";
import ReactGA from "react-ga";
import H2_Imagen from "../../Components/H2_Imagen/H2_Imagen";

import { Helmet } from 'react-helmet';


const Promociones = () => {

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);

  return (
    <div>
      <Helmet>
      <title>Nuestras promociones MesVida</title>
      <meta name="description" content="Descubre las últimas promociones y ofertas especiales en nuestro gimnasio. Desde descuentos en membresías hasta clases gratuitas, tenemos ofertas para ayudarte a alcanzar tus objetivos de fitness de manera asequible y emocionante. Aprovecha nuestras promociones y únete a nuestra comunidad de fitness hoy mismo." />
      {/* Add more meta tags as needed */}
      </Helmet>
      <BannerH1 height={800} title="Promociones MesVida" backgroundImg={imgBackground} />



      <H2_Imagen
        title="¡INSCRIBITE ANTES DE MARZO!"
        image={imgPromocion1}
        bgColor={theme.palette.background.secondary}
      />

<WaveSeparator
        fillColor={theme.palette.background.secondary}
        bgColor={theme.palette.background.primary}
      />
    </div>
  );
};

export default Promociones;

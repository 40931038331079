// src/pages/EntrenamientoPersonal/EntrenamientoPersonal.js

import React, { useEffect } from "react";
import BannerH1 from "../../Components/BannerH1/BannerH1";
import CenteredText from "../../Components/CenteredText/CenteredText";
import CardSection3 from "../../Components/CardSection3/CardSection3";
import ImageGallery from "../../Components/ImageGallery/ImageGallery";
import WaveSeparator from "../../Components/WaveSeparator/WaveSeparator";

import imgCardPerdidaPeso from "../../assets/FotosEntrenamientoPersonal/cards/PerdidaPeso.jpg";
import imgCardPruebasFisicas from "../../assets/FotosEntrenamientoPersonal/cards/PruebasFisicas.jpg";
import RecuperacionLesiones from "../../assets/FotosEntrenamientoPersonal/cards/RecuperacionLesiones.jpg";
import ReactGA from "react-ga";

import backgroundImg from "../../assets/FotosEntrenamientoPersonal/resized/DSC01538-38-min.jpg"; // Ajusta la ruta según la ubicación de tu imagen de fondo
import Img1 from "../../assets/FotosEntrenamientoPersonal/resized/DSC01530-32.jpg";
import Img2 from "../../assets/FotosEntrenamientoPersonal/resized/DSC01532-33.jpg";
import Img3 from "../../assets/FotosEntrenamientoPersonal/resized/DSC01536-36-min.jpg";
import Img4 from "../../assets/FotosEntrenamientoPersonal/resized/DSC01537-37-min.jpg";
import Img5 from "../../assets/FotosEntrenamientoPersonal/resized/DSC01538-38-min.jpg";
import Img6 from "../../assets/FotosEntrenamientoPersonal/resized/DSC01539-39-min.jpg";
import Img7 from "../../assets/FotosEntrenamientoPersonal/resized/DSC01540-40-min.jpg";
import Img8 from "../../assets/FotosEntrenamientoPersonal/resized/DSC01543-41-min.jpg";
import Img9 from "../../assets/FotosEntrenamientoPersonal/resized/DSC01544-42-min.jpg";
import Img10 from "../../assets/FotosEntrenamientoPersonal/resized/DSC01545-43-min.jpg";
import Img11 from "../../assets/FotosEntrenamientoPersonal/resized/DSC01546-44-min.jpg";
import Img12 from "../../assets/FotosEntrenamientoPersonal/resized/DSC01549-47-min.jpg";
import Img13 from "../../assets/FotosEntrenamientoPersonal/resized/DSC01550-48-min.jpg";

import theme from "../../theme";

import { Helmet } from 'react-helmet';


const FacilidadesCards = [
  {
    title: "Perdida de peso",
    description:
      "¡Adelgaza con éxito en nuestro gimnasio! Nuestros expertos te guiarán en el camino hacia tus metas de pérdida de peso. ",
    image: imgCardPerdidaPeso,
    backgroundColor: theme.palette.card.background, // o cualquier otro color válido en el tema de Material-UI
    textColor: theme.palette.card.textColor, // o cualquier otro color válido en el tema de Material-UI
  },
  {
    title: "Recuperación",
    description:
      "Contamos con profesionales especializados para guiarte en tu rehabilitación. ¡Vuelve a sentirte fuerte! ",
    image: RecuperacionLesiones,
    backgroundColor: theme.palette.card.background, // o cualquier otro color válido en el tema de Material-UI
    textColor: theme.palette.card.textColor, // o cualquier otro color válido en el tema de Material-UI
  },
  {
    title: "Preparación física",
    description:
      "Prepárate para pruebas deportivas con nosotros. Optimiza tu rendimiento y alcanza tus objetivos con entrenamientos personalizados.",
    image: imgCardPruebasFisicas,
    backgroundColor: theme.palette.card.background, // o cualquier otro color válido en el tema de Material-UI
    textColor: theme.palette.card.textColor, // o cualquier otro color válido en el tema de Material-UI
  },
];

const images = [
  {
    src: Img1,
    alt: "Descripción de la imagen 1",
    title: "Título de la imagen 1",
  },
  {
    src: Img2,
    alt: "Descripción de la imagen 1",
    title: "Título de la imagen 1",
  },
  {
    src: Img3,
    alt: "Descripción de la imagen 1",
    title: "Título de la imagen 1",
  },
  {
    src: Img4,
    alt: "Descripción de la imagen 1",
    title: "Título de la imagen 1",
  },
  {
    src: Img5,
    alt: "Descripción de la imagen 1",
    title: "Título de la imagen 1",
  },
  {
    src: Img6,
    alt: "Descripción de la imagen 1",
    title: "Título de la imagen 1",
  },
  {
    src: Img7,
    alt: "Descripción de la imagen 1",
    title: "Título de la imagen 1",
  },
  {
    src: Img8,
    alt: "Descripción de la imagen 1",
    title: "Título de la imagen 1",
  },
  {
    src: Img9,
    alt: "Descripción de la imagen 1",
    title: "Título de la imagen 1",
  },
  {
    src: Img10,
    alt: "Descripción de la imagen 1",
    title: "Título de la imagen 1",
  },
  {
    src: Img11,
    alt: "Descripción de la imagen 1",
    title: "Título de la imagen 1",
  },
  {
    src: Img12,
    alt: "Descripción de la imagen 1",
    title: "Título de la imagen 1",
  },
  {
    src: Img13,
    alt: "Descripción de la imagen 1",
    title: "Título de la imagen 1",
  },
];

const EntrenamientoPersonal = () => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);

  return (
    <div>
      <Helmet>
      <title>Entrenamiento personal en MesVida</title>
      <meta name="description" content="Descubre cómo nuestro equipo de entrenadores personales puede ayudarte a alcanzar tus objetivos de fitness de manera efectiva. Ofrecemos programas personalizados adaptados a tus necesidades, ya sea que busques perder peso, mejorar tu fuerza o aumentar tu resistencia. ¡Comienza tu viaje hacia una vida más saludable hoy mismo con nuestro entrenamiento personalizado!" />
      {/* Add more meta tags as needed */}
      </Helmet>

      <BannerH1
        height={800}
        title="Entrenamiento personal"
        backgroundImg={backgroundImg}
      />
      <CenteredText
        title="¡Transforma tu vida con nuestro exclusivo servicio de Entrenamiento Personal!"
        text="Nuestro enfoque único en el acompañamiento diario garantiza que te mantengas motivado, enfocado y constantemente desafiado. Con el apoyo de nuestros entrenadores profesionales y altamente capacitados, no solo alcanzarás tus objetivos de acondicionamiento físico, sino que también aprenderás a adoptar un estilo de vida saludable y sostenible. ¡No esperes más! Da el primer paso hacia la mejor versión de ti mismo y descubre el verdadero poder de un entrenamiento personalizado y una atención incomparable. ¡Únete a nosotros y experimenta la diferencia de tener un compañero en cada paso de tu viaje de transformación!"
        backgroundColor={theme.palette.background.primary}
      />

      <WaveSeparator
        fillColor={theme.palette.background.primary}
        bgColor={theme.palette.background.secondary}
      />

      <CardSection3
        title="Nuestros clientes suelen buscar"
        cardsInfo={FacilidadesCards}
        bckgrColor={theme.palette.background.secondary}
      />
      <WaveSeparator
        fillColor={theme.palette.background.secondary}
        bgColor={theme.palette.background.primary}
      />

      <ImageGallery
        title="Echa un vistazo a nuestro espacio"
        images={images}
        columns={3}
        bckground={theme.palette.background.primary}
      />
      <WaveSeparator
        fillColor={theme.palette.background.primary}
        bgColor={theme.palette.background.secondary}
      />
      <WaveSeparator
        fillColor={theme.palette.background.secondary}
        bgColor={theme.palette.background.primary}
      />
    </div>
  );
};

export default EntrenamientoPersonal;

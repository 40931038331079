// src/pages/EntrenamientoPersonal/EntrenamientoPersonal.js

import React, { useEffect } from "react";
import BannerH1 from "../../Components/BannerH1/BannerH1";
import CenteredText from "../../Components/CenteredText/CenteredText";
import CardSection3 from "../../Components/CardSection3/CardSection3";
import ImageGallery from "../../Components/ImageGallery/ImageGallery";
import WaveSeparator from "../../Components/WaveSeparator/WaveSeparator";

import imgCardapp from "../../assets/FotosGimnasio/cards/app.jpg";
import imgCardfingerprint from "../../assets/FotosGimnasio/cards/fingerprint.jpg";
import imgCardLimitacionAforo from "../../assets/FotosGimnasio/cards/LimitacionAforo.jpg";

import imgCardculturismo from "../../assets/FotosGimnasio/cards/culturismo.jpg";
import imgSeguimiento from "../../assets/Images/SeguimientoDeportivo.jpg";
import imgCardnutricion from "../../assets/FotosGimnasio/cards/nutricion.jpg";

import backgroundImg from "../../assets/Planta baja/Entrada/Rotulo.jpeg"; // Ajusta la ruta según la ubicación de tu imagen de fondo
import Img1 from "../../assets/Planta baja/Box crosstraining/Box crosstraining.jpeg";
import Img2 from "../../assets/Planta baja/Box crosstraining/Material crosstraining.jpeg";
import Img3 from "../../assets/Planta baja/Box crosstraining/Rack cross training.jpeg";
import Img4 from "../../assets/Planta baja/Box crosstraining/dumbbell.jpeg";
import Img5 from "../../assets/Planta baja/Peso Libre/Bancos.jpeg";
import Img6 from "../../assets/Planta baja/Peso Libre/Mancuerneros.jpeg";
import Img7 from "../../assets/Planta baja/Peso Libre/Multiestación.jpeg";
import Img8 from "../../assets/Planta baja/Peso Libre/Multipower.jpeg";
import Img9 from "../../assets/Planta baja/Peso Libre/Peso libre.jpeg";
import Img10 from "../../assets/Planta baja/Peso Libre/Zona peso libre 1.jpeg";
import Img11 from "../../assets/Planta baja/Recepción/Recepción.jpeg";
import Img12 from "../../assets/Planta baja/Recepción/Suplementación deportiva.jpeg";
import Img13 from "../../assets/Planta baja/Vista general planta baja/Vista general p.baja.jpeg";
import Img14 from "../../assets/Planta baja/Zona cardio/Zona cardio 1.jpeg";
import Img15 from "../../assets/Planta baja/Zona cardio/Zona cardio.jpeg";
import Img16 from "../../assets/Planta baja/Zona funcional/Cuerda.jpeg";
import Img17 from "../../assets/Planta baja/Zona funcional/Zona funcional.jpeg";
import Img18 from "../../assets/Planta baja/Zona maquinaria/Vista general de maquinaria.jpeg";
import Img19 from "../../assets/Planta baja/Zona maquinaria/Deltoides/Deltoides posterior.jpeg";
import Img20 from "../../assets/Planta baja/Zona maquinaria/Deltoides/Press militar.jpeg";
import Img21 from "../../assets/Planta baja/Zona maquinaria/Espalda/Jalon.jpeg";
import Img22 from "../../assets/Planta baja/Zona maquinaria/Espalda/Remo bajo polea.jpeg";
import Img23 from "../../assets/Planta baja/Zona maquinaria/Espalda/Remo bajo.jpeg";
import Img24 from "../../assets/Planta baja/Zona maquinaria/Pectoral/Press plano Press inclinado.jpeg";
import Img25 from "../../assets/Planta baja/Zona maquinaria/Pectoral/aperturas.jpeg";
import Img26 from "../../assets/Planta baja/Zona maquinaria/Piernas/Jaca y hip thrust.jpeg";
import Img27 from "../../assets/Planta baja/Zona maquinaria/Piernas/Prensa.jpeg";
import Img28 from "../../assets/Planta primera/Nuestro lema.jpeg";
import Img29 from "../../assets/Planta primera/Sala colectiva/Boxing.jpeg";
import Img30 from "../../assets/Planta primera/Sala colectiva/Sala colectiva 1.jpeg";
import Img31 from "../../assets/Planta primera/Sala colectiva/Sala colectiva espejos.jpeg";
import Img32 from "../../assets/Planta primera/Sala colectiva/Sala colectiva genral.jpeg";
import Img33 from "../../assets/Planta primera/Vestuarios/Vestuario femenino 1.jpeg";
import Img34 from "../../assets/Planta primera/Vestuarios/vestuario femenino.jpeg";
import Img35 from "../../assets/Planta primera/Vestuarios/vestuario masculino.jpeg";
import Img36 from "../../assets/Planta primera/Vestuarios/vestuario masculino 1.jpeg";
import Img37 from "../../assets/Planta primera/Vestuarios/vestuario masculino 3.jpeg";

import theme from "../../theme";
import ReactGA from "react-ga";

import { Helmet } from "react-helmet";
const FacilidadesCards = [
  {
    title: "APP para reservas",
    description:
      "Simplificamos la reserva y seguimiento de tus sesiones de entrenamiento. Asegura tu lugar en el gimnasio de manera conveniente con nuestra aplicación.",
    image: imgCardapp,
    backgroundColor: theme.palette.card.background, // o cualquier otro color válido en el tema de Material-UI
    textColor: theme.palette.card.textColor, // o cualquier otro color válido en el tema de Material-UI
  },
  {
    title: "Lector de huella",
    description:
      "Accede de manera segura y sin complicaciones a nuestras instalaciones con solo un toque. Olvídate de las tarjetas y experimenta la comodidad.",
    image: imgCardfingerprint,
    backgroundColor: theme.palette.card.background, // o cualquier otro color válido en el tema de Material-UI
    textColor: theme.palette.card.textColor, // o cualquier otro color válido en el tema de Material-UI
  },
  {
    title: "AFORO LIMITADO",
    description:
      "Disfruta de un ambiente exclusivo y seguro en nuestro gimnasio. Con un aforo limitado, garantizamos tu comodidad y seguridad mientras entrenas. Olvídate de las multitudes y aprovecha al máximo tu experiencia de entrenamiento.",
    image: imgCardLimitacionAforo,
    backgroundColor: theme.palette.card.background, // o cualquier otro color válido en el tema de Material-UI
    textColor: theme.palette.card.textColor, // o cualquier otro color válido en el tema de Material-UI
  },
];

const ServiciosCards = [
  {
    title: "Culturismo natural",
    description:
      "Nuestro enfoque de culturismo es natural y saludable. Te ayudamos a alcanzar tus metas de acondicionamiento físico de forma segura y auténtica, sin sustancias artificiales.",
    image: imgCardculturismo,
    backgroundColor: theme.palette.card.background, // o cualquier otro color válido en el tema de Material-UI
    textColor: theme.palette.card.textColor, // o cualquier otro color válido en el tema de Material-UI
  },
  {
    title: "Seguimiento deportivo",
    description:
      "Nuestro servicio de seguimiento deportivo te ayuda a alcanzar tus objetivos de rendimiento. Ofrecemos un enfoque personalizado y basado en datos para mejorar tu entrenamiento y desempeño. Confía en nosotros para elevar tu rendimiento.",
    image: imgSeguimiento,
    backgroundColor: theme.palette.card.background, // o cualquier otro color válido en el tema de Material-UI
    textColor: theme.palette.card.textColor, // o cualquier otro color válido en el tema de Material-UI
  },
  {
    title: "Nutrición y dietética",
    description:
      "Nuestro servicio de nutrición y dietética te apoya en tus metas de bienestar con orientación personalizada y un enfoque en la nutrición saludable. Alcanza un estado óptimo de salud y bienestar con nosotros.",
    image: imgCardnutricion,
    backgroundColor: theme.palette.card.background, // o cualquier otro color válido en el tema de Material-UI
    textColor: theme.palette.card.textColor, // o cualquier otro color válido en el tema de Material-UI
  },
];

const images = [
  {
    src: Img1,
    alt: "Descripción de la imagen 1",
    title: "Título de la imagen 1",
  },
  {
    src: Img2,
    alt: "Descripción de la imagen 1",
    title: "Título de la imagen 1",
  },
  {
    src: Img3,
    alt: "Descripción de la imagen 1",
    title: "Título de la imagen 1",
  },
  {
    src: Img4,
    alt: "Descripción de la imagen 1",
    title: "Título de la imagen 1",
  },
  {
    src: Img5,
    alt: "Descripción de la imagen 1",
    title: "Título de la imagen 1",
  },
  {
    src: Img6,
    alt: "Descripción de la imagen 1",
    title: "Título de la imagen 1",
  },
  {
    src: Img7,
    alt: "Descripción de la imagen 1",
    title: "Título de la imagen 1",
  },
  {
    src: Img8,
    alt: "Descripción de la imagen 1",
    title: "Título de la imagen 1",
  },
  {
    src: Img9,
    alt: "Descripción de la imagen 1",
    title: "Título de la imagen 1",
  },
  {
    src: Img10,
    alt: "Descripción de la imagen 1",
    title: "Título de la imagen 1",
  },
  {
    src: Img11,
    alt: "Descripción de la imagen 1",
    title: "Título de la imagen 1",
  },
  {
    src: Img12,
    alt: "Descripción de la imagen 1",
    title: "Título de la imagen 1",
  },
  {
    src: Img13,
    alt: "Descripción de la imagen 1",
    title: "Título de la imagen 1",
  },
  {
    src: Img14,
    alt: "Descripción de la imagen 1",
    title: "Título de la imagen 1",
  },
  {
    src: Img15,
    alt: "Descripción de la imagen 1",
    title: "Título de la imagen 1",
  },
  {
    src: Img17,
    alt: "Descripción de la imagen 1",
    title: "Título de la imagen 1",
  },
  {
    src: Img18,
    alt: "Descripción de la imagen 1",
    title: "Título de la imagen 1",
  },
  {
    src: Img19,
    alt: "Descripción de la imagen 1",
    title: "Título de la imagen 1",
  },
  {
    src: Img20,
    alt: "Descripción de la imagen 1",
    title: "Título de la imagen 1",
  },
  {
    src: Img21,
    alt: "Descripción de la imagen 1",
    title: "Título de la imagen 1",
  },
  {
    src: Img22,
    alt: "Descripción de la imagen 1",
    title: "Título de la imagen 1",
  },
  {
    src: Img23,
    alt: "Descripción de la imagen 1",
    title: "Título de la imagen 1",
  },
  {
    src: Img24,
    alt: "Descripción de la imagen 1",
    title: "Título de la imagen 1",
  },
  {
    src: Img25,
    alt: "Descripción de la imagen 1",
    title: "Título de la imagen 1",
  },
  {
    src: Img26,
    alt: "Descripción de la imagen 1",
    title: "Título de la imagen 1",
  },
  {
    src: Img27,
    alt: "Descripción de la imagen 1",
    title: "Título de la imagen 1",
  },
  {
    src: Img28,
    alt: "Descripción de la imagen 1",
    title: "Título de la imagen 1",
  },
  {
    src: Img29,
    alt: "Descripción de la imagen 1",
    title: "Título de la imagen 1",
  },
  {
    src: Img30,
    alt: "Descripción de la imagen 1",
    title: "Título de la imagen 1",
  },
  {
    src: Img31,
    alt: "Descripción de la imagen 1",
    title: "Título de la imagen 1",
  },
  {
    src: Img32,
    alt: "Descripción de la imagen 1",
    title: "Título de la imagen 1",
  },
  {
    src: Img33,
    alt: "Descripción de la imagen 1",
    title: "Título de la imagen 1",
  },
  {
    src: Img34,
    alt: "Descripción de la imagen 1",
    title: "Título de la imagen 1",
  },
  {
    src: Img35,
    alt: "Descripción de la imagen 1",
    title: "Título de la imagen 1",
  },
  {
    src: Img36,
    alt: "Descripción de la imagen 1",
    title: "Título de la imagen 1",
  },
  {
    src: Img37,
    alt: "Descripción de la imagen 1",
    title: "Título de la imagen 1",
  },
];

const Gimnasio = () => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);

  return (
    <div>
      <Helmet>
        <title>Gimnasio MesVida</title>
        <meta
          name="description"
          content="Descubre nuestras modernas instalaciones diseñadas para ofrecerte una experiencia de fitness excepcional. Desde equipos de última generación hasta espacios amplios y luminosos, nuestro gimnasio está equipado para satisfacer todas tus necesidades de entrenamiento. Explora nuestras instalaciones y encuentra el ambiente perfecto para alcanzar tus metas de salud y bienestar."
        />
        {/* Add more meta tags as needed */}
      </Helmet>

      <BannerH1
        height={800}
        title="Gimnasio MesVida"
        backgroundImg={backgroundImg}
      />

      <CenteredText
        title="¡Adentrate en nuestra experiencia como Gimnasio Independiente de calidad!"
        text="En nuestro gimnasio independiente, te ofrecemos una experiencia única que te mantendrá siempre motivado y desafiado. Con un amplio surtido de máquinas de alta calidad y el respaldo de nuestros entrenadores altamente capacitados, no solo alcanzarás tus objetivos de acondicionamiento físico, sino que también aprenderás a mantener un estilo de vida saludable y sostenible. ¡No esperes más! Da el primer paso hacia la mejor versión de ti mismo y descubre el poder de un gimnasio independiente con una atención sin igual. Únete a nosotros y experimenta la diferencia de tener un compañero en cada etapa de tu viaje hacia la transformación!"
        backgroundColor={theme.palette.background.primary}
      />

      <WaveSeparator
        fillColor={theme.palette.background.primary}
        bgColor={theme.palette.background.secondary}
      />

      <CardSection3
        title="Nuestras facilidades"
        cardsInfo={FacilidadesCards}
        bckgrColor={theme.palette.background.secondary}
      />
      <WaveSeparator
        fillColor={theme.palette.background.secondary}
        bgColor={theme.palette.background.primary}
      />

      <CardSection3
        title="Nuestros servicios"
        cardsInfo={ServiciosCards}
        bckgrColor={theme.palette.background.primary}
      />
      <WaveSeparator
        fillColor={theme.palette.background.primary}
        bgColor={theme.palette.background.secondary}
      />

      <ImageGallery
        title="Echa un vistazo a nuestro espacio"
        images={images}
        columns={3}
        bckground={theme.palette.background.secondary}
      />
      <WaveSeparator
        fillColor={theme.palette.background.secondary}
        bgColor={theme.palette.background.primary}
      />
    </div>
  );
};

export default Gimnasio;

import React from 'react';
import PropTypes from 'prop-types';
import './H2_Texto.css';
import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';



const H2_Texto = ({ title, image, bgColor }) => {
  const theme = useTheme();

  return (
    <section
      style={{ backgroundColor: bgColor }}
      className="section-container"
    >
      <div className="h2-texto-container">
        <Typography variant="h2" sx={{ fontFamily: theme.typography.fontFamily }}>
          {title}
        </Typography>
        <div className="section-texto-container">
          
        <span
            style={{
              color: theme.palette.tertiary.main,
              fontFamily: theme.typography.h1.fontFamily,
              fontSize: "28px",
              fontStyle: "italic",
            }}
          >
          Noooooo rotundo, esta definición no nos sirve.                    
          </span>
          <br></br>
          <span
            style={{
              color: theme.palette.secondary.main,
              fontFamily: theme.typography.h1.fontFamily,
              fontSize: "40px",
              fontWeight: "bolder",
              marginLeft: "10px",
            }}
          >
            ¡SOMOS MESVIDA !
          </span>
          <br></br>
          <br></br>
          <span
            style={{
              color: theme.palette.tertiary.main,
              fontFamily: theme.typography.h1.fontFamily,
              fontSize: "1rem",
              
            }}
          >
          Somos una filosofía, un estilo de vida donde nuestro compromiso es lograr que las personas adquieran hábitos de vida saludable, donde nuestro principal objetivo es conseguir que las personas alcancen sus objetivos.                     
          </span>
          <br></br>
          <br></br>

          <span
            style={{
              color: theme.palette.secondary.main,
              fontFamily: theme.typography.h1.fontFamily,
              fontSize: "40px",
              fontWeight: "bolder",
              marginLeft: "10px",
            }}
          >
            ¿Cuál es el secreto?
          </span>
          <br></br>
          <span
            style={{
              color: theme.palette.tertiary.main,
              fontFamily: theme.typography.h1.fontFamily,
              fontSize: "1rem",
              
            }}
          >
          Trabajando los pilares fundamentales que nos sustentan y que para MESVIDA son esenciales en nuestra vida diaria como: ESFUERZO, COMPROMISO y MOTIVACIÓN.          
          </span>

          <br></br>
          <br></br>
          <span
            style={{
              color: theme.palette.tertiary.main,
              fontFamily: theme.typography.h1.fontFamily,
              fontSize: "1rem",
              
            }}
          >
          Este último pilar mucha veces nos falla tanto en nuestros hábitos saludables como en el entrenamiento.  
          </span>
         
        </div>
      </div>
    </section>
  );
};


H2_Texto.propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  bgColor: PropTypes.string.isRequired,
};

export default H2_Texto;

import React, { useEffect } from "react";
import BannerInicio from "../../Components/BannerInicio/BannerInicio";
import H2_Texto from "../../Components/H2_Texto/H2_Texto";
import comoFuncionamosImage from "../../assets/Images/como-funcionamos.png"; // Importa tu imagen aquí
import theme from "../../theme";
import "./index.css";
import WaveSeparator from "../../Components/WaveSeparator/WaveSeparator";
import ReactGA from "react-ga";

import backgroundImg from "../../assets/Planta baja/Vista general planta baja/Vista general p.baja.jpeg"; // Ajusta la ruta según la ubicación de tu imagen de fondo

import { Helmet } from 'react-helmet';


const Inicio = () => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);

  return (
    <>
      <Helmet>
      
      <title>MesVida Fitness</title>
      <meta name="description" content="Bienvenido a nuestro gimnasio en abrera, donde la salud y el bienestar son nuestra prioridad. Descubre una amplia gama de servicios, desde entrenamiento personalizado hasta clases grupales, diseñadas para ayudarte a alcanzar tus metas de fitness. Únete a nuestra comunidad hoy mismo y comienza tu viaje hacia una vida más activa y saludable." />
      {/* Add more meta tags as needed */}
      </Helmet>
      <BannerInicio height={800} backgroundImg={backgroundImg} />
      <H2_Texto
        title="¿ES UN GIMNASIO CONVENCIONAL?"
        image={comoFuncionamosImage}
        bgColor={theme.palette.background.secondary}
      />
{/*
      <H2_Imagen
        title="¿Cómo funcionamos?"
        image={comoFuncionamosImage}
        bgColor={theme.palette.background.primary}
      />
      <WaveSeparator
        fillColor={theme.palette.background.primary}
        bgColor={theme.palette.background.secondary}
      />

      Comentamos servicios
      <Servicios />
      <WaveSeparator
        fillColor={theme.palette.background.secondary}
        bgColor={theme.palette.background.primary}
      />

      <ValueSectionComponent />
      <WaveSeparator
        fillColor={theme.palette.background.primary}
        bgColor={theme.palette.background.secondary}
      />

      <Testimonials />


      <ImagenTextoIzquierda 
        title="Nuestros servicios"
        image={backgroundImg}
        bgColor={theme.palette.background.secondary}/>*/}

      <WaveSeparator
        fillColor={theme.palette.background.secondary}
        bgColor={theme.palette.background.primary}
      />
    </>
  );
};

export default Inicio;

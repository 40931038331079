// src/pages/EntrenamientoPersonal/EntrenamientoPersonal.js

import React, { useEffect } from "react";
import BannerH1 from "../../Components/BannerH1/BannerH1";
import ActividadLeft from "../../Components/ActividadLeft/ActividadLeft";
import ActividadRight from "../../Components/ActividadRight/ActividadRight";

import WaveSeparator from "../../Components/WaveSeparator/WaveSeparator";

import imgBackground from "../../assets/Planta primera/Sala colectiva/Sala colectiva genral.jpeg";

import imgBoxingFit from "../../assets/FotosActividades/BoxingFit.jpeg";
import imgGAC from "../../assets/FotosActividades/GAC.jpg";
import imgFuncionalTraining from "../../assets/FotosActividades/FuncionalTraining.jpg";
import imgCoreTraining from "../../assets/FotosActividades/CoreTraining.jpg";
import imgPilates from "../../assets/FotosActividades/Pilates.jpg";
import imgBC from "../../assets/FotosActividades/BodyCombat.jpg";
import imgBP from "../../assets/FotosActividades/BodyPump.jpg";
import imgHit from "../../assets/FotosActividades/hiit.webp";
import imgZumba from "../../assets/FotosActividades/Zumba.jpg";


import theme from "../../theme";
import ReactGA from "react-ga";
import H2 from "../../Components/H2/H2";

import { Helmet } from 'react-helmet';



const Actividades = () => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);

  return (
    <div>
      <Helmet>
        <title>Actividades MesVida</title>
        <meta name="description" content="Descubre las emocionantes actividades que ofrecemos en nuestro gimnasio. Desde clases como Pilates y hasta entrenamientos como Body Pump. ¡Únete a nosotros y alcanza tus metas de acondicionamiento físico hoy mismo!" />
        {/* Add more meta tags as needed */}
      </Helmet>

      <BannerH1 height={800} title="Actividades MesVida" backgroundImg={imgBackground} />
      <H2
        title="Nuestras actividades presenciales"
        bgColor={theme.palette.background.secondary}
      />
      <ActividadLeft
        titulo={"BOXING FIT (CARDIO)"}
        texto={
          <span>
            Actividad de alta intensidad por intervalos de tiempo donde tu
            cuerpo y mente adquieren beneficios como: resistencia
            cardiovascular, muscular, agilidad, aumento de gasto calórico ,
            tonicidad muscular tanto en el tren superior como inferior, aumento
            de producción de endorfinas y una descarga de adrenalina. Sudaremos
            la camiseta, gritaremos y desprenderemos felicidad a topeeee.
          </span>
        }
        image={imgBoxingFit}
        bgColor={theme.palette.background.secondary}
      />

      <ActividadRight
        titulo={"G.A.C (FUERZA)"}
        texto={
          <span>
            Actividad destinada al trabajo de fuerza de Glúteos, Abdominales y
            Piernas al ritmo de música donde a parte de realizar un
            entrenamiento muy específico nos lo pasaremos genial.
          </span>
        }
        image={imgGAC}
        bgColor={theme.palette.background.secondary}
      />

      <ActividadLeft
        titulo={"FUNCIONAL TRAINING (ALTA INTENSIDAD)"}
        texto={
          <span>
            Actividad basada en los movimientos cotidianos y naturales del
            cuerpo humano donde trabajaremos de forma global todas las partes de
            nuestro cuerpo. Una de sus principales ventajas es que se adaptan
            completamente a las condiciones físicas de cada persona, por eso
            resulta muy efectivo como entrenamiento personal: mejora la
            movilidad corporal, la agilidad y el equilibrio, desarrolla la salud
            cardiovascular, corrige la postura, fortalece la masa muscular y
            ayuda a mantener el peso.
          </span>
        }
        image={imgFuncionalTraining}
        bgColor={theme.palette.background.secondary}
      />

      <ActividadRight
        titulo={"CORE TRAINING (FUERZA)"}
        texto={
          <span>
            La palabra Core hace referencia al conjunto de músculos de la
            articulación lumbo-pelvico que son los músculos que estabilizan la
            columna vertebral y la región abdominal. La acción conjunta de estas
            estructuras permite tener un control y una estabilidad corporal.
          </span>
        }
        image={imgCoreTraining}
        bgColor={theme.palette.background.secondary}
      />

      <ActividadLeft
        titulo={"PILATES (CUERPO/MENTE)"}
        texto={
          <span>
            Hacer pilates te permitirá ejercitar y tonificar los músculos,
            mejorar tu flexibilidad, equilibrio y coordinación. Además, entre
            más los practiques tu postura mejorará, lo cual no sólo te hará
            lucir más estilizada sino que también evitará el dolor y las
            lesiones. Con estos ejercicios podrás fortalecer tus músculos y
            ganar resistencia, lo que te permitirá realizar tus actividades
            diarias con más facilidad. También promueve la concentración y la
            reducción del estrés, beneficiando no sólo tu cuerpo sino también tu
            mente.
          </span>
        }
        image={imgPilates}
        bgColor={theme.palette.background.secondary}
      />

      <ActividadRight
        titulo={"BODY COMBAT (ALTA INTENSIDAD)"}
        texto={
          <span>
            Se trata de una actividad de carácter aeróbico que combina
            movimientos de diferentes disciplinas como karate, boxeo o
            taekwondo, entre otros, con la música. Así, la sesión va
            transcurriendo a través de distintas coreografías que incorporan
            puñetazos, patadas al aire y demás ejercicios al ritmo de las
            canciones más cañeras. Es una actividad ideal para ayudar a perder
            peso.
          </span>
        }
        image={imgBC}
        bgColor={theme.palette.background.secondary}
      />
      <ActividadLeft
        titulo={"BODY PUMP (FUERZA)"}
        texto={
          <span>
            El Body Pump es un programa de entrenamiento físico intenso que
            combina actividad aeróbica y trabajo muscular mediante el
            levantamiento de pesas al ritmo de la música. Son sesiones
            dirigidas, divertidas y motivadoras, en las que se fortalece el
            sistema cardiovascular y la gran mayoría de los músculos del cuerpo.
          </span>
        }
        image={imgBP}
        bgColor={theme.palette.background.secondary}
      />

      <ActividadRight
        titulo={"HIIT (ALTA INTENSIDAD)"}
        texto={
          <span>
            El entrenamiento de alta intensidad, también conocido como HIIT, es
            la práctica de actividades físicas que elevan tu frecuencia
            cardíaca, alternadas con otras que la disminuyen. Esto permite que
            tengas una recuperación parcial e incompleta para volver a iniciar
            los ejercicios de alta exigencia, además, puede convertirse en una
            letal herramienta para mejorar tu composición corporal
          </span>
        }
        image={imgHit}
        bgColor={theme.palette.background.secondary}
      />

      <ActividadLeft
        titulo={"ZUMBA (CARDIO)"}
        texto={
          <span>
            Disciplina deportiva que se imparte en clases dirigidas en la que se
            realizan ejercicios aeróbicos al ritmo de música latina (merengue,
            samba, reggaeton, cumbia y salsa) con la finalidad de perder peso de
            forma divertida y mejorar el estado de ánimo de los deportistas.
          </span>
        }
        image={imgZumba}
        bgColor={theme.palette.background.secondary}
      />

<WaveSeparator
        fillColor={theme.palette.background.secondary}
        bgColor={theme.palette.background.primary}
      />
    </div>
  );
};

export default Actividades;

import React from 'react';
import PropTypes from 'prop-types';
import './H2.css';
import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';



const H2 = ({ title, bgColor }) => {
  const theme = useTheme();

  return (
    <section
      style={{ backgroundColor: bgColor }}
      className="section-container"
    >
      <div className="h2-texto-container">
        <Typography variant="h2" sx={{ fontFamily: theme.typography.fontFamily }}>
          {title}
        </Typography>
       
      </div>
    </section>
  );
};


H2.propTypes = {
  title: PropTypes.string.isRequired,
  bgColor: PropTypes.string.isRequired,
};

export default H2;

// src/components/CenteredText/CenteredText.js

import React from "react";
import { Typography, Container } from "@mui/material";
import "./PreguntaFAQ.css";

const PreguntaFAQ = ({ pregunta, respuesta, backgroundColor }) => {
  return (
    <Container
      sx={{
        backgroundColor: backgroundColor,
        minWidth: "100%", // Establecer la altura mínima a 100% del viewport height
        padding: "2%",
      }}
    >
      <div className="FAQ">
        <div className="CajaFAQ">
          <Typography variant="h3" align="left" sx={{ color: "white" }}>
            {pregunta}
          </Typography>
          <span>{respuesta}</span>
        </div>
      </div>
    </Container>
  );
};

export default PreguntaFAQ;

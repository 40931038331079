import React from "react";
import PropTypes from "prop-types";
import "./ActividadLeft.css";
import { Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const ImagenTextoIzquierda = ({ titulo,texto, image, bgColor }) => {
  const theme = useTheme();

  return (
    <section style={{ backgroundColor: bgColor }} className="section-container">


      <div className="container-zigzag-left  gradient-left">

        <div className="ZigZag-image-container">
          <img src={image} alt="imagen" className="section-image" />

        </div>

        <div className="section-text-container" >
          
          <Typography
            variant="h3"
            sx={{ fontFamily: theme.typography.fontFamily }}
          >
            {titulo}
          </Typography>
          {texto}


        </div>
      </div>


    </section>
  );
};

ImagenTextoIzquierda.propTypes = {
  texto: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  bgColor: PropTypes.string.isRequired,
};

export default ImagenTextoIzquierda;

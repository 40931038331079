
import React, { useEffect }from "react";
import BannerH1 from "../../Components/BannerH1/BannerH1";
import ImageGallery from "../../Components/ImageGallery/ImageGallery";
import WaveSeparator from "../../Components/WaveSeparator/WaveSeparator";




import theme from "../../theme";
import ReactGA from "react-ga";

import ImgEP1 from '../../assets/FotosEntrenamientoPersonal/resized/DSC01530-32.jpg'
import ImgEP2 from '../../assets/FotosEntrenamientoPersonal/resized/DSC01532-33.jpg'
import ImgEP3 from '../../assets/FotosEntrenamientoPersonal/resized/DSC01536-36-min.jpg'
import ImgEP4 from '../../assets/FotosEntrenamientoPersonal/resized/DSC01537-37-min.jpg'
import ImgEP5 from '../../assets/FotosEntrenamientoPersonal/resized/DSC01538-38-min.jpg'
import ImgEP6 from '../../assets/FotosEntrenamientoPersonal/resized/DSC01539-39-min.jpg'
import ImgEP7 from '../../assets/FotosEntrenamientoPersonal/resized/DSC01540-40-min.jpg'
import ImgEP8 from '../../assets/FotosEntrenamientoPersonal/resized/DSC01543-41-min.jpg'
import ImgEP9 from '../../assets/FotosEntrenamientoPersonal/resized/DSC01544-42-min.jpg'
import ImgEP10 from '../../assets/FotosEntrenamientoPersonal/resized/DSC01545-43-min.jpg'
import ImgEP11 from '../../assets/FotosEntrenamientoPersonal/resized/DSC01546-44-min.jpg'
import ImgEP12 from '../../assets/FotosEntrenamientoPersonal/resized/DSC01549-47-min.jpg'
import ImgEP13 from '../../assets/FotosEntrenamientoPersonal/resized/DSC01550-48-min.jpg'

import Img1 from "../../assets/Planta baja/Box crosstraining/Box crosstraining.jpeg";
import Img2 from "../../assets/Planta baja/Box crosstraining/Material crosstraining.jpeg";
import Img3 from "../../assets/Planta baja/Box crosstraining/Rack cross training.jpeg";
import Img4 from "../../assets/Planta baja/Box crosstraining/dumbbell.jpeg";
import Img5 from "../../assets/Planta baja/Peso Libre/Bancos.jpeg";
import Img6 from "../../assets/Planta baja/Peso Libre/Mancuerneros.jpeg";
import Img7 from "../../assets/Planta baja/Peso Libre/Multiestación.jpeg";
import Img8 from "../../assets/Planta baja/Peso Libre/Multipower.jpeg";
import Img9 from "../../assets/Planta baja/Peso Libre/Peso libre.jpeg";
import Img10 from "../../assets/Planta baja/Peso Libre/Zona peso libre 1.jpeg";
import Img11 from "../../assets/Planta baja/Recepción/Recepción.jpeg";
import Img12 from "../../assets/Planta baja/Recepción/Suplementación deportiva.jpeg";
import Img13 from "../../assets/Planta baja/Vista general planta baja/Vista general p.baja.jpeg";
import Img14 from "../../assets/Planta baja/Zona cardio/Zona cardio 1.jpeg";
import Img15 from "../../assets/Planta baja/Zona cardio/Zona cardio.jpeg";
import Img16 from "../../assets/Planta baja/Zona funcional/Cuerda.jpeg";
import Img17 from "../../assets/Planta baja/Zona funcional/Zona funcional.jpeg";
import Img18 from "../../assets/Planta baja/Zona maquinaria/Vista general de maquinaria.jpeg";
import Img19 from "../../assets/Planta baja/Zona maquinaria/Deltoides/Deltoides posterior.jpeg";
import Img20 from "../../assets/Planta baja/Zona maquinaria/Deltoides/Press militar.jpeg";
import Img21 from "../../assets/Planta baja/Zona maquinaria/Espalda/Jalon.jpeg";
import Img22 from "../../assets/Planta baja/Zona maquinaria/Espalda/Remo bajo polea.jpeg";
import Img23 from "../../assets/Planta baja/Zona maquinaria/Espalda/Remo bajo.jpeg";
import Img24 from "../../assets/Planta baja/Zona maquinaria/Pectoral/Press plano Press inclinado.jpeg";
import Img25 from "../../assets/Planta baja/Zona maquinaria/Pectoral/aperturas.jpeg";
import Img26 from "../../assets/Planta baja/Zona maquinaria/Piernas/Jaca y hip thrust.jpeg";
import Img27 from "../../assets/Planta baja/Zona maquinaria/Piernas/Prensa.jpeg";
import Img28 from "../../assets/Planta primera/Nuestro lema.jpeg";
import Img29 from "../../assets/Planta primera/Sala colectiva/Boxing.jpeg";
import Img30 from "../../assets/Planta primera/Sala colectiva/Sala colectiva 1.jpeg";
import Img31 from "../../assets/Planta primera/Sala colectiva/Sala colectiva espejos.jpeg";
import Img32 from "../../assets/Planta primera/Sala colectiva/Sala colectiva genral.jpeg";
import Img33 from "../../assets/Planta primera/Vestuarios/Vestuario femenino 1.jpeg";
import Img34 from "../../assets/Planta primera/Vestuarios/vestuario femenino.jpeg";
import Img35 from "../../assets/Planta primera/Vestuarios/vestuario masculino.jpeg";
import Img36 from "../../assets/Planta primera/Vestuarios/vestuario masculino 1.jpeg";
import Img37 from "../../assets/Planta primera/Vestuarios/vestuario masculino 3.jpeg";



import { Helmet } from 'react-helmet';

const imagesEP = [
  
  {
    src:ImgEP1,
     alt: 'Descripción de la imagen 1',
    title: 'Título de la imagen 1',
      },
    {
    src:ImgEP2,
     alt: 'Descripción de la imagen 1',
    title: 'Título de la imagen 1',
      },
    {
    src:ImgEP3,
     alt: 'Descripción de la imagen 1',
    title: 'Título de la imagen 1',
      },
    {
    src:ImgEP4,
     alt: 'Descripción de la imagen 1',
    title: 'Título de la imagen 1',
      },
    {
    src:ImgEP5,
     alt: 'Descripción de la imagen 1',
    title: 'Título de la imagen 1',
      },
    {
    src:ImgEP6,
     alt: 'Descripción de la imagen 1',
    title: 'Título de la imagen 1',
      },
    {
    src:ImgEP7,
     alt: 'Descripción de la imagen 1',
    title: 'Título de la imagen 1',
      },
    {
    src:ImgEP8,
     alt: 'Descripción de la imagen 1',
    title: 'Título de la imagen 1',
      },
    {
    src:ImgEP9,
     alt: 'Descripción de la imagen 1',
    title: 'Título de la imagen 1',
      },
    {
    src:ImgEP10,
    alt: 'Descripción de la imagen 1',
    title: 'Título de la imagen 1',
      },
    {
    src:ImgEP11,
    alt: 'Descripción de la imagen 1',
    title: 'Título de la imagen 1',
      },
    {
    src:ImgEP12,
    alt: 'Descripción de la imagen 1',
    title: 'Título de la imagen 1',
      },
    {
    src:ImgEP13,
    alt: 'Descripción de la imagen 1',
    title: 'Título de la imagen 1',
      },
  ];


  const imagesG = [
    {
      src: Img1,
      alt: "Descripción de la imagen 1",
      title: "Título de la imagen 1",
    },
    {
      src: Img2,
      alt: "Descripción de la imagen 1",
      title: "Título de la imagen 1",
    },
    {
      src: Img3,
      alt: "Descripción de la imagen 1",
      title: "Título de la imagen 1",
    },
    {
      src: Img4,
      alt: "Descripción de la imagen 1",
      title: "Título de la imagen 1",
    },
    {
      src: Img5,
      alt: "Descripción de la imagen 1",
      title: "Título de la imagen 1",
    },
    {
      src: Img6,
      alt: "Descripción de la imagen 1",
      title: "Título de la imagen 1",
    },
    {
      src: Img7,
      alt: "Descripción de la imagen 1",
      title: "Título de la imagen 1",
    },
    {
      src: Img8,
      alt: "Descripción de la imagen 1",
      title: "Título de la imagen 1",
    },
    {
      src: Img9,
      alt: "Descripción de la imagen 1",
      title: "Título de la imagen 1",
    },
    {
      src: Img10,
      alt: "Descripción de la imagen 1",
      title: "Título de la imagen 1",
    },
    {
      src: Img11,
      alt: "Descripción de la imagen 1",
      title: "Título de la imagen 1",
    },
    {
      src: Img12,
      alt: "Descripción de la imagen 1",
      title: "Título de la imagen 1",
    },
    {
      src: Img13,
      alt: "Descripción de la imagen 1",
      title: "Título de la imagen 1",
    },
    {
      src: Img14,
      alt: "Descripción de la imagen 1",
      title: "Título de la imagen 1",
    },
    {
      src: Img15,
      alt: "Descripción de la imagen 1",
      title: "Título de la imagen 1",
    },
    {
      src: Img17,
      alt: "Descripción de la imagen 1",
      title: "Título de la imagen 1",
    },
    {
      src: Img18,
      alt: "Descripción de la imagen 1",
      title: "Título de la imagen 1",
    },
    {
      src: Img19,
      alt: "Descripción de la imagen 1",
      title: "Título de la imagen 1",
    },
    {
      src: Img20,
      alt: "Descripción de la imagen 1",
      title: "Título de la imagen 1",
    },
    {
      src: Img21,
      alt: "Descripción de la imagen 1",
      title: "Título de la imagen 1",
    },
    {
      src: Img22,
      alt: "Descripción de la imagen 1",
      title: "Título de la imagen 1",
    },
    {
      src: Img23,
      alt: "Descripción de la imagen 1",
      title: "Título de la imagen 1",
    },
    {
      src: Img24,
      alt: "Descripción de la imagen 1",
      title: "Título de la imagen 1",
    },
    {
      src: Img25,
      alt: "Descripción de la imagen 1",
      title: "Título de la imagen 1",
    },
    {
      src: Img26,
      alt: "Descripción de la imagen 1",
      title: "Título de la imagen 1",
    },
    {
      src: Img27,
      alt: "Descripción de la imagen 1",
      title: "Título de la imagen 1",
    },
    {
      src: Img28,
      alt: "Descripción de la imagen 1",
      title: "Título de la imagen 1",
    },
    {
      src: Img29,
      alt: "Descripción de la imagen 1",
      title: "Título de la imagen 1",
    },
    {
      src: Img30,
      alt: "Descripción de la imagen 1",
      title: "Título de la imagen 1",
    },
    {
      src: Img31,
      alt: "Descripción de la imagen 1",
      title: "Título de la imagen 1",
    },
    {
      src: Img32,
      alt: "Descripción de la imagen 1",
      title: "Título de la imagen 1",
    },
    {
      src: Img33,
      alt: "Descripción de la imagen 1",
      title: "Título de la imagen 1",
    },
    {
      src: Img34,
      alt: "Descripción de la imagen 1",
      title: "Título de la imagen 1",
    },
    {
      src: Img35,
      alt: "Descripción de la imagen 1",
      title: "Título de la imagen 1",
    },
    {
      src: Img36,
      alt: "Descripción de la imagen 1",
      title: "Título de la imagen 1",
    },
    {
      src: Img37,
      alt: "Descripción de la imagen 1",
      title: "Título de la imagen 1",
    },
  ];
const GaleriaImagenes = () => {

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);

  return (
    <div>

      <Helmet>
      <title>Nuestras imágenes MesVida</title>
      <meta name="description" content="Explora nuestra galería de imágenes y descubre las instalaciones de vanguardia, las clases emocionantes y el ambiente vibrante de nuestro gimnasio. Déjate inspirar y motívate para alcanzar tus metas de fitness mientras descubres lo que nuestro gimnasio tiene para ofrecer." />
      {/* Add more meta tags as needed */}
      </Helmet>

      <BannerH1 height={800} title="Nuestros espacios MesVida" backgroundImg={ImgEP1} />
      <WaveSeparator
        fillColor={theme.palette.background.secondary}
        bgColor={theme.palette.background.primary}
      />
      <ImageGallery
        images={imagesG}
        title={"Nuestro gimnasio"}
        columns={3}
        bckground={theme.palette.background.primary}
      />

<WaveSeparator
        fillColor={theme.palette.background.primary}
        bgColor={theme.palette.background.secondary}
      />
      <ImageGallery
        images={imagesEP}
        title={"Entrenamiento personal"}
        columns={3}
        bckground={theme.palette.background.secondary}
      />
<WaveSeparator
        fillColor={theme.palette.background.secondary}
        bgColor={theme.palette.background.primary}
      />
    </div>
  );
};

export default GaleriaImagenes;

// src/pages/EntrenamientoPersonal/EntrenamientoPersonal.js

import React, { useEffect } from "react";
import BannerH1 from "../../Components/BannerH1/BannerH1";
import PreguntaFAQ from "../../Components/PreguntaFAQ/PreguntaFAQ";
import WaveSeparator from "../../Components/WaveSeparator/WaveSeparator";

import Img2 from "../../assets/FotosGimnasio/DSC01495-9-min.jpg";

import theme from "../../theme";
import ReactGA from "react-ga";
import H2 from "../../Components/H2/H2";

import { Helmet } from 'react-helmet';

const FAQs = () => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);

  return (
    <div>
      <Helmet>
      <title>Preguntas frecuentes MesVida</title>
      <meta name="description" content="Encuentra respuestas a las preguntas más comunes sobre nuestro gimnasio y servicios. Desde horarios de apertura y cierre hasta políticas de membresía, estamos aquí para proporcionarte toda la información que necesitas de manera clara y concisa. Simplifica tu experiencia en nuestro gimnasio consultando nuestras preguntas frecuentes hoy mismo." />
      {/* Add more meta tags as needed */}
      </Helmet>

      <BannerH1 height={800} title="FAQs MesVida" backgroundImg={Img2} />
      <H2
        title="¡TODO SOBRE MI INSCRIPCION!"
        bgColor={theme.palette.background.secondary}
      />
      <PreguntaFAQ
        pregunta="¿TODAVÍA NO ESTOY INCRITO A LA FAMILIA MESVIDA, POR DÓNDE EMPIEZO?"
        respuesta="Puedes venir a visitarnos a nuestro centro y decirnos cuáles son tus objetivos y tus horarios, por dónde te gustaría empezar y nuestro staff te explicará, asesorará y enseñará el centro. También te explicará nuestras tarifas y te resolverá todas las dudas que te surjan.
        Te explicarán todas las instalaciones de las que dispones, servicios y horarios."
        backgroundColor={theme.palette.background.secondary}
      />
      <PreguntaFAQ
        pregunta="¿CÓMO ME PUEDO DAR DE ALTA?"
        respuesta="Darse de alta es muy sencillo, puedes hacerlo en el centro con nuestro staff o desde el formulario en la pestaña alta online donde nos llegaran tus datos y agilizaremos tú inscripción.en la que solo tendrás que llegar al centro y confirmar datos."
        backgroundColor={theme.palette.background.secondary}
      />
      <PreguntaFAQ
        pregunta="¿PUEDO PROBAR LAS INSTALACIONES ANTES DE INSCRIBIRME?"
        respuesta="Para poder probar las instalaciones deberás tener una invitación, esta se entrega en la recepción del centro, disponemos de unidades puntuales mensuales. Cada persona solo podrá tener una invitación gratuita mensual. En la recepción tomarán tus datos y podrás entrenar. Si después quieres realizar otra prueba, tendrás que comprar el pase de día.
Es obligatorio traer el documento de identidad y si eres menor de edad deberá venir un tutor para firmar la autorización de uso de instalaciones."
        backgroundColor={theme.palette.background.secondary}
      />
      <PreguntaFAQ
        pregunta="¿QUÉ TIPO DE CUOTAS EXISTEN?"
        respuesta="Para conocer los precios consulta dentro del apartado Alta online donde podrás conocer las tarifas vigentes y promociones de nuestro centro.
También te recomendamos acercarte a nuestras instalaciones para conocer nuestras ofertas puntuales."
        backgroundColor={theme.palette.background.secondary}
      />
      <PreguntaFAQ
        pregunta="¿QUÉ SERVICIOS INCLUYE LA CUOTA?"
        respuesta="Acceso libre sin limitaciones horarias, acceso libre a todas las actividades dirigidas del centro excepto Cross Traninng , uso del wifi. Vestuarios equipados con taquillas de uso puntual, que funcionan con candado que deberás traer tú mismo, secadores y duchas individuales."
        backgroundColor={theme.palette.background.secondary}
      />
      <PreguntaFAQ
        pregunta="¿PUEDO SACAR PASE DIARIO?"
        respuesta="Puedes adquirir un pase de un solo uso directamente en nuestro centro."
        backgroundColor={theme.palette.background.secondary}
      />
      <PreguntaFAQ
        pregunta="¿A PARTIR DE QUÉ EDAD PUEDO ACCEDER A ENTRENAR?"
        respuesta="La edad mínima para inscribirse en el club son 16 años cumplidos. Hasta que cumplas la mayoría de edad, es necesario que tu padre/madre o tutor firme tu contrato y tu domiciliación bancaria."
        backgroundColor={theme.palette.background.secondary}
      />
      <PreguntaFAQ
        pregunta="FORMAS DE PAGO"
        respuesta="En el momento de la inscripción se realiza el primer pago mediante tarjeta de crédito/débito, en las cuotas mensuales el pago es obligado mediante domiciliación sepa o pasarela de pago por tarjeta de debito/credito. "
        backgroundColor={theme.palette.background.secondary}
      />
      <PreguntaFAQ
        pregunta="¿ES CIERTO QUE NO EXISTE PERMANENCIA?"
        respuesta="Sí, simplemente deberás darte de baja antes del día 20 del mes anterior al que quieras que tu baja se haga efectiva. Podrás entrenar hasta el último día del mes."
        backgroundColor={theme.palette.background.secondary}
      />

<WaveSeparator
        fillColor={theme.palette.background.secondary}
        bgColor={theme.palette.background.primary}
      />
      <H2
        title="YA ESTOY INSCRITO"
        bgColor={theme.palette.background.primary}
      />

      <PreguntaFAQ
        pregunta="¿CÓMO ACCEDO EL PRIMER DÍA AL CENTRO?"
        respuesta="Si has tramitado la alta vía web deberás traer el documento de inscripción o email de conformidad y enseñarlo en la recepción del centro para que puedan darte tu medio de acceso a las isntalaciones. Si el alta se ha realizado en el mismo centro podrás acceder a él desde el mismo momento que realizas el pago."
        backgroundColor={theme.palette.background.primary}
      />
      <PreguntaFAQ
        pregunta="USO DE TOALLA"
        respuesta="El uso de la toalla es obligatorio en todo el gimnasio, clases y espacios donde sea necesario.
Mesvidafitness se reserva el derecho de no dejar entrenar a aquellos socios/as sin ella."
        backgroundColor={theme.palette.background.primary}
      />
      <PreguntaFAQ
        pregunta="COLOCACIÓN DEL MATERIAL"
        respuesta="Es obligatorio colocar el material utilizado tanto en clases como en sala de fitness. Entre todos cuidamos el espacio y respetamos a los demás."
        backgroundColor={theme.palette.background.primary}
      />
      <PreguntaFAQ
        pregunta="CUOTA DE MANTENIMIENTO"
        respuesta="Tenemos una cuota llamada excedencia, debes contratarla antes del día 20 del mes anterior al que quieras que se haga efectiva. Sirve para vacaciones, enfermedades o viajes de trabajo y siempre será por mes natural de día 1 a día 1."
        backgroundColor={theme.palette.background.primary}
      />
      <PreguntaFAQ
        pregunta="¿QUÉ PASA SI MI BANCO NO PAGA UNA CUOTA?"
        respuesta="Cuando el banco devuelve un recibo, se bloquea automáticamente la entrada al club hasta que se haga frente a ese impago. Además, deberás efectuar el pago de una penalización en concepto de gastos bancarios.
Si quieres tramitar tu baja, tendrás que hacerlo después de abonar el recibo pendiente.
Si decides inscribirte de nuevo, deberás abonar el pago pendiente."
        backgroundColor={theme.palette.background.primary}
      />
      <PreguntaFAQ
        pregunta="¿CÓMO PUEDO DARME DE BAJA?"
        respuesta="Puedes tramitarla directamente en el club o por email  bajas@mesvidafitness.com
Todas las bajas solicitadas hasta el día 20 del mes en curso se tramitan para finales del mismo mes, posterior a esta fecha serán para el mes siguiente.
Sea cual sea el motivo de baja, el importe de la matrícula, pulsera y de los meses pendientes no son reembolsables en ningún caso.
Es importante que para cualquier tipo de reclamación siempre tengas el documento de baja del centro o el email pidiendo dicha baja, no servirá la notificación verbal."
        backgroundColor={theme.palette.background.primary}
      />
<WaveSeparator
        fillColor={theme.palette.background.primary}
        bgColor={theme.palette.background.secondary}
      />
      <H2
        title="BOX DE CROSSTRAINING"
        bgColor={theme.palette.background.secondary}
      />

      <PreguntaFAQ
        pregunta="¿EL USO DEL BOX DE CROSS ES GRATUITO?"
        respuesta="No, el Box de Crosstraining está reservado para los clientes que tengan pagada la cuota específica de uso."
        backgroundColor={theme.palette.background.secondary}
      />
      <PreguntaFAQ
        pregunta="¿QUÉ COSTE TIENEN LAS CLASES DE CROSSTRAINING?"
        respuesta="Si quieres información acerca de las tarifas, pasa por la recepción del centro para que te informen de calendarios y precios de las actividades que realizamos."
        backgroundColor={theme.palette.background.secondary}
      />
      <PreguntaFAQ
        pregunta="¿SE PUEDE PROBAR UNA CLASE?"
        respuesta="Si, pasa por recepción e informate, te ayudarán a reservar la clase de prueba"
        backgroundColor={theme.palette.background.secondary}
      />

<WaveSeparator
        fillColor={theme.palette.background.secondary}
        bgColor={theme.palette.background.primary}
      />
    </div>
  );
};

export default FAQs;

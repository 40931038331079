// src/components/ContactUs.js
import React, { useState } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import emailjs from "emailjs-com";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faMapMarkerAlt,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import { useTheme } from "@mui/material/styles";
import { padding } from "@mui/system";

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    description: "",
  });

  
  const theme = useTheme();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const [submitMessage, setSubmitMessage] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_9f6negx",
        "template_ys7basn",
        e.target,
        "CV71sZubqZdpfnrKQ"
      )
      .then(
        (result) => {
          setSubmitMessage("El correo se ha enviado con éxito");
          setFormData({
            name: "",
            email: "",
            phone: "",
            description: "",
          });
        },
        (error) => {
          setSubmitMessage(
            "Hubo un error al enviar el correo. Por favor, inténtalo de nuevo."
          );

          console.log(error.text);
        }
      );
  };

  return (
    <Container
      id="contacto"
      maxWidth="lg"
      sx={{
        backgroundColor: theme.palette.background.primary,
        minWidth: "100%", // Establecer la altura mínima a 100% del viewport height
        padding: "2%",
        borderRadius: "5px",
      }}
    >
      <Typography variant="h2" align="center" color="white">
        Contacta con nosotros
      </Typography>
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "50px",
          width: "90%",
          margin: "0 auto",
          p: 4,
          backgroundColor: "white",
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} sm={8}>
            <Container sx={{ padding: "0, 100px" }}>
              <form onSubmit={handleSubmit}>
                <TextField
                  fullWidth
                  required
                  name="name"
                  label="Nombre"
                  value={formData.name}
                  onChange={handleChange}
                  inputprops={{ maxLength: 500 }}
                  sx={{ borderRadius: "5px", mb: 2 }}
                />
                <TextField
                  fullWidth
                  name="email"
                  label="Correo electrónico"
                  type="email"
                  value={formData.email}
                  onChange={handleChange}
                  inputprops={{ maxLength: 150 }}
                  sx={{ borderRadius: "5px", mb: 2 }}
                />
                <TextField
                  fullWidth
                  name="phone"
                  label="Teléfono"
                  value={formData.phone}
                  onChange={handleChange}
                  inputprops={{ maxLength: 150 }}
                  sx={{ borderRadius: "5px", mb: 2 }}
                />
                <TextareaAutosize
                  required
                  fullWidth
                  name="description"
                  label="Descripción de la consulta"
                  value={formData.description}
                  onChange={handleChange}
                  inputprops={{ maxLength: 2000 }}
                  minRows={4}
                  maxRows={6}
                  placeholder="Descripción de la consulta"
                  style={{
                    width: "100%",
                    borderRadius: "5px",
                    marginBottom: "8px",
                    padding: "8px",
                  }}
                />
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Typography variant="subtitle2">
                    {formData.description.length} de 2000 máximo de caracteres
                  </Typography>
                </div>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{
                      backgroundColor: theme.palette.secondary.main,
                      color: theme.palette.tertiary.main,
                      "&:hover": {
                        backgroundColor: theme.palette.tertiary.main,
                        color: theme.palette.primary.main,
                      },
                      fontFamily: theme.typography.button.fontFamily,
                    }}
                  >
                    Enviar
                  </Button>
                </div>
              </form>
              {submitMessage && <div>{submitMessage}</div>}
            </Container>
          </Grid>
          <Grid item xs={12} sm={4}>
            <div sx={{ mt: 2, borderRadius: "5px", overflow: "hidden" }}>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2987.257795072679!2d1.8864265764699601!3d41.52035618773637!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12a48bc223c5e2e9%3A0xf0741a18e0b3603d!2sCarrer%20Progr%C3%A9s%2C%2030%2C%2008630%20Abrera%2C%20Barcelona!5e0!3m2!1ses!2ses!4v1703423890633!5m2!1ses!2ses"
                width="100%"
                height="200"
                frameBorder="0"
                style={{ border: 0 }}
                allowFullScreen=""
                aria-hidden="false"
                tabIndex="0"
              ></iframe>
            </div>
            <div>
              <FontAwesomeIcon icon={faPhone} />
              <Typography variant="subtitle1" component="span" sx={{ ml: 1 }}>
                (+34) 692 87 16 59
              </Typography>
            </div>
            <div>
              <FontAwesomeIcon icon={faMapMarkerAlt} />
              <Typography variant="subtitle1" component="span" sx={{ ml: 1 }}>
                Carrer del Progrés 30, Abrera, España
              </Typography>
            </div>
            <div>
              <FontAwesomeIcon icon={faEnvelope} />
              <Typography variant="subtitle1" component="span" sx={{ ml: 1 }}>
                info@mesvidafitness.com
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Container>
    </Container>
  );
};

export default ContactUs;
